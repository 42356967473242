import React from "react";

function Resume() {
  return (
    <div className="Resume" id="resume">
      <br />
      <p> If you are interested please contact me! Also check linkedIn.</p>
    </div>
  );
}
export default Resume;
